import React from "react";
import { useState } from "react/cjs/react.development";
import styles from "./styles.module.scss";

export const Notification = ({ title, subtitle }) => {
  const [active, setActive] = useState();
  return (
    <div className={styles.root + " " + (active && styles.active)}>
      <div className={styles.info}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
      <button onClick={() => setActive(!active)}>
        {active ? "Off" : "On"}
      </button>
    </div>
  );
};
