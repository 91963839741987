import React from 'react'
import styles from './styles.module.scss'

export const Promo = ({ onReedem }) => {
  return (
    <div className={styles.main}>
      <h5>Promo codes</h5>
      <p>Most promo codes have an expiry date</p>
      <form action="">
        <input type="text" placeholder="Add promo code" />
        <button onClick={onReedem} type="button">Reedem</button>
      </form>
    </div>
  )
}
