import React from 'react'
import styles from './styles.module.scss'

export const NavIcon = ({ active = false, src, activeSrc }) => {
  return (
    <div className={`${active ? styles.active : ''} ${styles.iconWrapper}`}>
      <img src={active ? activeSrc : src} alt="icon"/>
    </div>
  )
}
