import React, { useState, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import { Message } from "../../components/common/Message";
import { ModalView } from "../../components/common/ModalView";
import { CardSection } from "./common/CardSection";
import { useSelector, useDispatch } from "react-redux";
import { getFile, getRecentMessages } from "../../requests.js";
import { MessageForm } from "./common/MessageForm";
import Loader from "react-loader-spinner";

export const Messages = () => {
  const user = useSelector((state) => state.user).user;
  const dispatch = useDispatch();

  const [brands] = useState(user?.projects || {});
  const [choosedProject, setProject] = useState({});
  const [messageParams, setMessageParams] = useState([]);
  const [records, setRecords] = useState([]);
  const [outcome, setOutcome] = useState([]);
  const [choosed, setChoosed] = useState(null);
  const [empty, setEmpty] = useState(false);
  const [recentMessages, setRecentMessages] = useState(
    user?.recentMessages || []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (choosedProject.name) {
      getFile(user.userId, choosedProject.name).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setRecords(data.records);
            data.head[data.head.length - 1] = data.head[
              data.head.length - 1
            ].replace("\r", "");
            setMessageParams(data.head);
          });
        } else {
          //do something
        }
      });
    }
  }, [choosedProject]);

  useEffect(() => {
    if (user && Object.keys(choosedProject).length === 0) {
      if (user.projects) {
        let keys = Object.keys(user.projects);
        setProject(user.projects[keys[0]]);
      }
    }
  }, [user]);

  useEffect(() => {
    if (recentMessages.length === 0 && user?.userId && !empty) {
      getRecentMessages(user?.userId).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            if (data.recent.length === 0) {
              setEmpty(true);
            } else {
              setRecentMessages(data.recent);
              dispatch({ type: "messages", payload: data.recent });
            }
          });
        }
      });
    }
  }, [recentMessages]);

  const cards = useMemo(() => {
    return Object.values(brands);
  }, [brands]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainBlock}>
          <div>
            <h4>Choose brand</h4>
            <CardSection
              page="messages"
              choosed={choosed}
              setChoosed={setChoosed}
              set={setProject}
              cards={cards}
            />
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className="mb-24">
            <MessageForm
              disabled={choosed === null}
              messageParams={messageParams}
              records={records}
              setOutcome={setOutcome}
              setLoading={setLoading}
              choosedProject={choosedProject}
            />
          </div>
          <div className={styles.recentMessage}>
            <h4>Recent messages</h4>
            {recentMessages.reverse().map((message, index) => (
              <Message {...message} key={index} />
            ))}
          </div>
        </div>
        <ModalView homePath="/messages" data={outcome} />
      </div>
      {loading && (
        <div className={styles.loaderContainer}>
          <Loader
            className={styles.loader}
            type="Oval"
            color="#ff7f32"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};
