import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react/cjs/react.development";
import styles from "./styles.module.scss";

export const SettingsNavItem = ({
  title,
  subtitle,
  icon,
  iconActive,
  path,
}) => {
  const [activeIcon, setActiveIcon] = useState(icon);
  const [className, setClassName] = useState(styles.root);
  return (
    <NavLink
      exact
      to={path}
      className={className}
      isActive={(match) => {
        if (match) {
          setActiveIcon(iconActive);
          setClassName(styles.root + " " + styles.active);
        } else {
          setActiveIcon(icon);
          setClassName(styles.root);
        }
      }}
    >
      <div className={styles.icon_container}>
        <img src={activeIcon} />
      </div>
      <div>
        <p className={styles.title}>{title}</p>
        <p className={styles.subtitle}>{subtitle}</p>
      </div>
    </NavLink>
  );
};
