import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"
import { NameInput } from "../../../inputs/NameInput"
import styles from "./styles.module.scss"
import dropIcon from "../../../../../public/assets/icons/drop_file.svg"
import dropError from "../../../../../public/assets/icons/dropError.svg"
import dropIconSuccess from "../../../../../public/assets/icons/dropIconSuccess.svg"
import { useSelector, useDispatch } from "react-redux"
import {
  editBrand,
  addColor,
  deleteBrand,
  getCountries
} from "../../../../../requests"
import Loader from "react-loader-spinner"
import { usePalette } from "react-palette"
import { useParams } from "react-router"
import { Dropdown } from "../../../Dropdown";
import { useHistory } from "react-router-dom";

const baseStyle = {};

const activeStyle = {
  borderColor: "#2196f3"
};

const acceptStyle = {
  borderColor: "#00e676"
};

const rejectStyle = {
  borderColor: "#ff1744"
};

export const ManageModal = () => {

  const user = useSelector(state => state.user).user;
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();

  const [uploadedPhoto, setUploadedPhoto] = useState();
  const [logo, setLogo] = useState(user?.projects[id]?.logo || null);
  const [color] = useState(user?.projects[id]?.color);
  const { data } = usePalette(uploadedPhoto);
  const [show, setShow] = useState(logo ? true : false);
  const [file, setFile] = useState(user?.projects[id]?.file);
  console.log(file)
  const [name, setName] = useState("");
  const [fileName] = useState(user?.projects[id]?.fileName);
  const [oldName] = useState(user?.projects[id]?.name);
  const [oldCountry] = useState(user?.projects[id]?.country || "");
  const [country, setCountry] = useState(user?.projects[id]?.country);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFile, setShowFile] = useState(true);
  const [showFileError, setShowFileError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDelete, setShowDelete] = useState(true);
  const [error, setError] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [phoneTypes, setPhoneTypes] = useState([]);

  useEffect(() => {
    getCountries().then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data)
          let countries = [];
          let types = [];
          data.countries.forEach((country) => {
            countries.push({
              label: country.country,
              value: country.countryCode,
            });
            if (Object.keys(country.subresourceUris).includes("mobile")) {
              types.push({ code: country.countryCode, type: "mobile" });
            } else if (
              !Object.keys(country.subresourceUris).includes("mobile") &&
              Object.keys(country.subresourceUris).includes("local")
            ) {
              types.push({ code: country.countryCode, type: "local" });
            } else {
              types.push({ code: country.countryCode, type: "tollFree" });
            }
          });
          setCountriesList(
            countries.sort((a, b) => a.label.localeCompare(b.label))
          );
          setPhoneTypes(types);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (name.length > 0 && file) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [name, file]);

  const onDrop = useCallback(acceptedFiles => {
    const doc = acceptedFiles[0];
    const validFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv"
    ];
    if (validFileTypes.includes(doc.type)) {
      if (doc.size <= 2097152) {
        var reader = new FileReader();
        reader.readAsText(doc, "UTF-8");
        reader.onload = function (evt) {
          let headers = evt.target.result
          .substr(0, evt.target.result
          .indexOf("\n")).split(",");

          if (headers.includes("Phone") || headers.includes("Phone Number")) {
            setFile(doc);
            setShowError(false);
            setShowFile(true);
          } else {
            setError("Your file needs to contain 'Phone' column");
            setShowFile(false);
            setShowError(true);
            setFile(null);
          }
        }
      } else {
        setError("Your file is too big. The limit for upload is 2MB")
        setShowFile(false);
        setShowError(true);
        setFile(null);
      }
    } else {
      setError("Please provide valid .csv or .xlsx file");
      setShowFile(false);
      setShowError(true);
      setFile(null);
    }
  }, [])
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);


  const handlePhotoChange = (event) => {
    const img = event.target.files[0];
    const validImageTypes = ["image/png", "image/jpeg"];
    if (validImageTypes.includes(img.type)) {
      setShow(true);
      setUploadedPhoto(window.URL.createObjectURL(
        new Blob([img], {
          type: img.type,
        })
      ));
      setLogo(img);
    } else {
      alert("Please provide valid image");
    }
  }

  const remove = () => {
    setLoading(true);
    deleteBrand(user.userId, oldName).then(response => {
      if (response.status === 200) {
        let brands = user.projects;
        delete brands[oldName];
        if (user.recentMessages) {
          let messages = user.recentMessages;
          messages.forEach((msg, index, object) => {
            if (msg.project === oldName) {
              object.splice(index, 1);
            }
          });
          dispatch({ type: "messages", payload: messages })
        }
        dispatch({ type: "deleteBrand", payload: brands });
        setLoading(false);
        window.location.href = "http://localhost:3000/settings/";
      } else {
        //something went wrong
      }
    });
  };

  const edit = () => {
    setLoading(true);
    if (name.length === 0) {
      //TODO: set error modal
      setLoading(false);
      return;
    }
    if (!country) {
      setLoading(false);
      return;
    }
    if (!file) {
      //TODO: set error modal
      setLoading(false);
      return;
    }
    if (user.projects) {
      for (let key in user.projects) {
        if (key === name && name !== oldName) {
          //TODO: set error modal
          setLoading(false);
          return;
        }
      }
    }

    const fdata = new FormData();
    fdata.append("userId", user.userId);
    fdata.append("logo", logo);
    fdata.append("oldName", oldName);
    fdata.append("oldFileName", fileName);
    fdata.append("name", name);
    fdata.append("file", file);
    fdata.append("country", country);
    fdata.append("oldCountry", oldCountry);
    let withLogo = false;
    if (logo !== null) {
      withLogo = true;
    }
    fdata.append("withLogo", withLogo);
    editBrand(fdata).then(response => {
      if (response.status === 200) {
        response.json().then(rdata => {
          let brands = user.projects || {};
          if (withLogo) {
            if (uploadedPhoto) {
              rdata.project["color"] = data.vibrant;
              addColor(user.userId, id, rdata.project["color"])
              .then(response => {
                if (response.status === 200) {
                  //do nothing
                } else {
                  //do something?
                }
              });
            } else {
              rdata.project["color"] = color;
            }

          }
          brands[name] = rdata.project;
          let position = Object.keys(user?.projects).indexOf(oldName);
          let newPosition = Object.keys(brands).indexOf(name);
          var arrBrands = Object.keys(brands)
            .map(function (k) { return { [k]: brands[k] } });
          [arrBrands[position], arrBrands[newPosition]] = [arrBrands[newPosition], arrBrands[position]];
          let final = {};
          arrBrands.forEach((b, index) => {
            for (let key in b) {
              let tmp = { ...b[key], position: index };
              final[key] = tmp;
            }
          });
          if (name !== oldName) {
            delete final[oldName];
          }
          dispatch({ type: "editBrand", payload: final });
          setLoading(false);
          window.location.href = "http://localhost:3000/settings/";
        })
      } else {
        setLoading(false);
        //TODO: set error modal
      }
    })
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {show ? <div className={styles.uploadWithoutImg}>
          <label htmlFor="file-input-logo-change">
            <img src={uploadedPhoto || logo} alt="logo" />
            <div className={styles.overlay} />
          </label>
          <input
            id="file-input-logo-change"
            type="file"
            style={{ display: "none" }}
            onChange={handlePhotoChange}
          />
        </div>
          : <div className={styles.upload}>
            <label htmlFor="file-input-logo-change">
              <div className={styles.overlay} />
            </label>
            <input
              id="file-input-logo-change"
              type="file"
              style={{ display: "none" }}
              onChange={handlePhotoChange}
            />
          </div>}
        <p className={styles.nameBrand}>{oldName}</p>
        {showDelete ?
          <button
            className={styles.deleteBrand}
            onClick={() => remove()}
          >
            <img
              src="/assets/basket.svg"
              width="12"
              height="15"
              alt="icon"
            />
            Remove
          </button>
        : null}
        <NameInput
          icon="/assets/file.svg"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter new name"
          style={{ marginTop: 16, textAlign: "center" }}
        />
        <Dropdown
          controlClassName={styles.country}
          options={countriesList}
          icon="/assets/earth.svg"
          placeholder={oldCountry}
          onChange={(e) => {
            setCountry(e.target.value);
          }}
        />
        {showFile ?
          <div
            {...getRootProps({ style })}
            className={styles.dragNdropSuccess}
          >
            <input {...getInputProps()} />
            <img src={dropIconSuccess} alt="Your file" />
            <div style={{ flexBasis: "100%", height: 0 }}></div>
            <h2>{file?.name || fileName}</h2>
          </div> :
          showFileError ?
          <div
            {...getRootProps({ style })}
            className={styles.dragNdropError}
          >
            <input {...getInputProps()} />
            <img src={dropError} alt="File error" />
            <div style={{ flexBasis: "100%", height: 0 }}></div>
            <h2>{file?.name || fileName}</h2>
          </div> :
          showError ?
            <div
              {...getRootProps({ style })}
              className={styles.dragNdropError}
            >
              <input {...getInputProps()} />
              <img src={dropError} alt="Error" />
              <div style={{ flexBasis: "100%", height: 0 }}></div>
              <h4>{error}</h4>
            </div> :
            <div
              {...getRootProps({ style })}
              className={styles.dragNdrop}
            >
              <input {...getInputProps()} />
              <img src={dropIcon} alt="Drop file here" />
            </div>
        }
        <div className={styles.controls}>
          <button
            className={styles.cancel}
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
          <button
            className={styles.save}
            disabled={disabled}
            onClick={() => edit()}
          >
            Save
          </button>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Loader
              className={styles.loader}
              type="Oval"
              color="#ff7f32"
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    </div >
  )
}
