import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import store from './redux/store';

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider >
      <Router>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
