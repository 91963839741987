import { React } from "react"
import Modal from "react-modal"
import { useHistory, useLocation } from "react-router-dom";
import { AddModal } from "../modals/settings/AddModal";
import { ManageModal } from "../modals/settings/ManageModal";
import { SubscribeModal } from "../modals/settings/SubscribeModal";
import { CostModal } from "../modals/settings/CostModal";
import { LogOutModal } from "../modals/settings/LogOutModal";
import { SuccessModal } from "../modals/settings/SuccessModal";
import styles from './styles.module.scss'


const customStyles = {
  overlay: {
    backdropFilter: "blur(4px)",
    zIndex: 5,
  },
  content: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    height: "100%",
    maxWidth: "720px",
    maxHeight: "735px",
    boxShadow: "0px 0px 68px rgba(0, 0, 0, 0.2)",
    borderRadius: "14px",
    padding: 0,
    width: "90%",
  },
};

Modal.setAppElement("#root");


function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const ModalView = ({ homePath = "/", data }) => {
  const history = useHistory();
  const modalRoute = useQuery();;

  return (
    <Modal
      style={customStyles}
      isOpen={!!(modalRoute.get("add") || modalRoute.get("manage") || modalRoute.get("browse") || modalRoute.get("subscribe") || modalRoute.get("cost")) || modalRoute.get("logout") || modalRoute.get("success")}
      onRequestClose={() => history.push(homePath)}
    >
      <div className={styles.wrapper}>
        {/* {modalRoute.get("manage") && <ManageModal id={modalRoute.get("manage")} callback={modalRoute.get("callback")} />} */}
        {modalRoute.get("browse") && <span>browse</span>}
        {modalRoute.get("cost") && <CostModal data={data} />}
        {modalRoute.get("logout") && <LogOutModal />}
        {modalRoute.get("success") && <SuccessModal type={modalRoute.get("type")} />}
        {modalRoute.get("subscribe") && <SubscribeModal />}
      </div>
    </Modal>
  )
}
