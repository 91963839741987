import React from "react";
import styles from "./styles.module.scss";

export const AnimatedSelectedIcon = ({color}) => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36 72C55.8823 72 72 55.8823 72 36C72 16.1178 55.8823 0 36 0C16.1178 0 0 16.1178 0 36C0 55.8823 16.1178 72 36 72Z"
        fill="white"
      />
      <polyline className={styles.selected} points='20 35 30 46 30 46 54 25' stroke={color} />
    </svg>
  );
};
