import { useEffect, useState } from "react";
import { removePaymentMethod, getPaymentMethods } from "../requests";
import { useSelector } from "react-redux";

export const useStructuredPaymentMethods = (stripeId) => {

  const user = useSelector((state) => state.user).user;
  const [data, setData] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    getPaymentMethods(stripeId).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let paymentMethods = [];
          data.forEach((pm, index) => {
            let month = pm.card.exp_month.toString();
            if (pm.card.exp_month < 10) {
              month = "0" + pm.card.exp_month.toString();
            }
            let year = pm.card.exp_year.toString().slice(-2);
            paymentMethods.push({
              id: index,
              type: pm.card.brand,
              name: pm.billing_details.name,
              month: month,
              year: year,
              pm: pm.id,
              last4: pm.card.last4,
            });
          });
          setData(paymentMethods);
        });
      } else {
        setData([]);
      }
    });
  }, [stripeId]);

  const deleteCard = (i) => {
    setDeleteLoading(true);
    setTimeout(() => {
      const card = data.find((card) => card.id === i);
      removePaymentMethod(user.stripeId, card.last4);
      setData(data.filter((item) => item.id !== card.id));
      setDeleteLoading(false);
    }, 1000);
  }

  return {data, deleteCard, deleteLoading};
};

