import React, { useState, useEffect } from "react";
import { OrangeButton } from "../../components/common/buttons/OrangeButton";
import styles from "./styles.module.scss";
import plusIcon from "../../public/assets/icons/plus.svg";
import { Select } from "../../components/common/Select";
import statistic from "../../public/assets/graphs/statistic_temp.svg";
import { Recent } from "../../components/common/Recent";
import { Balance } from "../../components/common/Balance";
import { Message } from "../../components/common/Message";
import { useSelector, useDispatch } from "react-redux";
import { getCompanies, getRecentMessages } from "../../requests";
import { BrandCard } from "../../components/common/BrandCard";
import { useHistory } from "react-router-dom";

export const Main = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user).user;
  const dispatch = useDispatch();

  // TODO: create layout for this pages (left-, right- blocks are same on this pages)
  const [recentMessages, setRecentMessages] = useState(
    user?.recentMessages || []
  );
  console.log(user?.recentMessages)
  const [recentCompanies, setRecentCompanies] = useState([]);
  const [company, setCompany] = useState(recentCompanies[0] || {});
  const [activeBrand, setActiveBrand] = useState(0);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    if (user) {
      getCompanies(user.userId).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setRecentCompanies(data.recent);
            setCompany(data.recent[0]);
          });
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (recentMessages.length === 0 && user?.userId && !empty) {
      getRecentMessages(user?.userId).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            if (data.recent.length === 0) {
              setEmpty(true);
            } else {
              setRecentMessages(data.recent);
              dispatch({ type: "messages", payload: data.recent });
            }
          });
        }
      });
    }
  }, [user, recentMessages]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainBlock}>
        <div className={styles.message}>
          <div className={styles.textContent}>
            <h6>Let's begin</h6>
            <h3>Write a message</h3>
          </div>
          <OrangeButton
            style={{
              height: 48,
              width: 121,
              padding: 12,
              fontSize: 18,
              background:"#FF7F32",
            }}
            icon={plusIcon}
            onClick={() => history.push('/messages')}
          >
            Create
          </OrangeButton>
        </div>
        <div className={styles.statistic}>
          <div className={styles.header}>
            <h4>Your statistics</h4>
            <Select>
              <option value="0">Daily</option>
              <option value="1">Weekly</option>
              <option value="2">Monthly</option>
              <option value="3">Yearly</option>
            </Select>
          </div>
          <div className={styles.main}>
            <img src={statistic} alt="your statistic" />
            <div className={styles.info}>
              <div className={styles.messages}>
                <h4>4,600</h4>
                <h6>Messages today</h6>
              </div>
              <div className={styles.percentage}>
                <span>2.5%</span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99637 2.00001C7.81086 1.99927 7.62511 2.07529 7.49166 2.22802L2.83164 7.56136C2.58938 7.83862 2.61776 8.25977 2.89502 8.50203C3.17228 8.74429 3.59344 8.71592 3.8357 8.43865L7.33367 4.43526L7.33367 13.3333C7.33367 13.7015 7.63215 14 8.00034 14C8.36853 14 8.66701 13.7015 8.66701 13.3333V4.44666L12.1656 8.43936C12.4082 8.71628 12.8294 8.74406 13.1064 8.50141C13.3833 8.25876 13.4111 7.83757 13.1684 7.56065L8.55208 2.29235C8.43214 2.1159 8.22978 2 8.00034 2C7.99901 2 7.99769 2 7.99637 2.00001Z"
                    fill="#FF7F32"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.recent}>
          <h4>Recent</h4>
          <div className={styles.mainRecent}>
            {recentCompanies.map(companyItem => (
              <Recent
                {...companyItem}
                key={companyItem.name}
                active={companyItem.name === company.name}
                onClick={() => setCompany(companyItem)}
              />
            ))}
          </div>
        </div> */}
        <div className={styles.recent}>
          <h4>Your brands</h4>
          <div className={styles.brands}>
            {[...recentCompanies, ...recentCompanies].map((companyItem, i) => (
              <BrandCard onClick={() => setActiveBrand(i)} {...companyItem} active={i === activeBrand} />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <div className={styles.recentMessage}>
          <h4>Recent messages</h4>
          {recentMessages?.reverse().map((message, index) => (
            <Message {...message} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
