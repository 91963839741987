import React from "react";
import { SettingsNavItem } from "../SettingNavItem";

export const SettingsNav = () => {
  return (
    <nav>
      <ul>
        <li>
          <SettingsNavItem
            path="/settings"
            subtitle="Edit photo, email, password"
            title="Edit profile"
            icon="/assets/edit.svg"
            iconActive="/assets/edit_active.svg"
          />
        </li>
        <li>
          <SettingsNavItem
            path="/settings/promo"
            subtitle="Check your promocodes"
            title="Promo codes"
            icon="/assets/promo.svg"
            iconActive="/assets/promo_active.svg"
          />
        </li>
        <li>
          <SettingsNavItem
            path="/settings/brands"
            subtitle="Add, edit and remove your brands"
            title="Edit brands"
            icon="/assets/brands.svg"
            iconActive="/assets/brands_active.svg"
          />
        </li>
        <li>
          <SettingsNavItem
            path="/settings/payments"
            subtitle="Here you can edit your payment methods"
            title="Edit payment methods"
            icon="/assets/payments.svg"
            iconActive="/assets/payments_active.svg"
          />
        </li>
        <li>
          <SettingsNavItem
            path="/settings/additional"
            subtitle="Change additional things"
            title="Additional settings"
            icon="/assets/additional.svg"
            iconActive="/assets/additional_active.svg"
          />
        </li>
      </ul>
    </nav>
  );
};
