import React from "react";
import { useState } from "react/cjs/react.development";
import styles from "./styles.module.scss";

export const Dropdown = ({ icon, placeholder, options, onChange }) => {
  console.log(options);
  const [isExpanded, setIsExpanded] = useState(false);
  const [value, setValue] = useState(null);
  const toggle = () => {
    setIsExpanded(!isExpanded);
  };
  const handleClick = (o) => {
    setValue(o.label);
    onChange(o);
  };
  return (
    <div onClick={toggle} className={styles.main + ' '+ (isExpanded && styles.shadow)}>
      <img
        src={icon}
        width="20"
        height="20"
        alt="drop"
      />
      <span className={!value && styles.placeholder}>
        {value || placeholder}
      </span>
      {isExpanded && (
        <div className={styles.options}>
          {options.map((o) => (
            <span onClick={() => handleClick(o)}>{o.label}</span>
          ))}
        </div>
      )}
    </div>
  );
};
