export default function user(state = {}, action) {
  switch (action.type) {
    case "login":
      return {
        ...state,
        user: action.payload
      }
    case "changeAvatar":
      return {
        ...state,
        user: { ...state.user, avatar: action.payload }
      }
    case "changeName":
      return {
        ...state,
        user: { ...state.user, fullName: action.payload }
      }
    case "changeEmail":
      return {
        ...state,
        user: { ...state.user, email: action.payload }
      }
    case "addBrand":
      return {
        ...state,
        user: { ...state.user, projects: action.payload }
      }
    case "editBrand":
      return {
        ...state,
        user: { ...state.user, projects: action.payload }
      }
    case "deleteBrand":
      return {
        ...state,
        user: { ...state.user, projects: action.payload }
      }
    case "balance":
      return {
        ...state,
        user: { ...state.user, balance: action.payload }
      }
    case "payments":
      return {
        ...state,
        user: { ...state.user, payments: action.payload }
      }
    case "messages":
      return {
        ...state,
        user: { ...state.user, recentMessages: action.payload }
      }
    case "changeFunctions":
      switch (action.payload[0]) {
        case "emailNotifications":
          return {
            ...state,
            user: { ...state.user, emailNotifications: action.payload[1]}
          }
        default:
          return state;
      }
    default:
      return state;
  };
};