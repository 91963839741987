import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { NameInput } from "../../../inputs/NameInput";
import styles from "./styles.module.scss";
import dropIconSuccess from "../../../../../public/assets/icons/dropIconSuccess.svg";
import {
  createBrand,
  addColor,
  getNumbers,
  getCountries,
} from "../../../../../requests";
import { useDispatch, useSelector } from "react-redux";
import { usePalette } from "react-palette";
import Loader from "react-loader-spinner";
import "react-dropdown/style.css";
import { Dropdown } from "../../../Dropdown";
import { useHistory } from "react-router-dom";

const baseStyle = {};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export const AddModal = () => {
  const user = useSelector((state) => state.user).user;
  const history = useHistory();
  const dispatch = useDispatch();

  const [uploadedPhoto, setUploadedPhoto] = useState();
  const { data } = usePalette(uploadedPhoto);
  const [show, setShow] = useState(true);
  const [logo, setLogo] = useState(null);
  const [file, setFile] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [category, setCategory] = useState("");
  const [countriesList, setCountriesList] = useState([]);
  const [choosedCountry, setCountry] = useState("");
  const [choosedNumber, setChoosedNumber] = useState("Select number");
  const [phoneTypes, setPhoneTypes] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [showFile, setShowFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorFormat, setShowErrorFormat] = useState(false);
  const [error, setError] = useState("");
  const [errorFormat, setErrorFormat] = useState("");

  useEffect(() => {
    getCountries().then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data)
          let countries = [];
          let types = [];
          data.countries.forEach((country) => {
            countries.push({
              label: country.country,
              value: country.countryCode,
            });
            if (Object.keys(country.subresourceUris).includes("mobile")) {
              types.push({ code: country.countryCode, type: "mobile" });
            } else if (
              !Object.keys(country.subresourceUris).includes("mobile") &&
              Object.keys(country.subresourceUris).includes("local")
            ) {
              types.push({ code: country.countryCode, type: "local" });
            } else {
              types.push({ code: country.countryCode, type: "tollFree" });
            }
          });
          setCountriesList(
            countries.sort((a, b) => a.label.localeCompare(b.label))
          );
          setPhoneTypes(types);
        });
      }
    });
  }, []);

  useEffect(() => {
    getCountries().then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          console.log(data)
          let countries = [];
          let types = [];
          data.countries.forEach((country) => {
            countries.push({
              label: country.country,
              value: country.countryCode,
            });
            if (Object.keys(country.subresourceUris).includes("mobile")) {
              types.push({ code: country.countryCode, type: "mobile" });
            } else if (
              !Object.keys(country.subresourceUris).includes("mobile") &&
              Object.keys(country.subresourceUris).includes("local")
            ) {
              types.push({ code: country.countryCode, type: "local" });
            } else {
              types.push({ code: country.countryCode, type: "tollFree" });
            }
          });
          setCountriesList(
            countries.sort((a, b) => a.label.localeCompare(b.label))
          );
          setPhoneTypes(types);
        });
      }
    });
  }, []);

  useEffect(() => {
    if (choosedCountry.length > 0) {
      let type = "";
      phoneTypes.forEach((t) => {
        if (t.code === choosedCountry) {
          type = t.type;
          //break;
        }
      });
      getNumbers(choosedCountry, type).then((response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setNumbers(data.phones);
          });
        }
      });
    }
  }, [choosedCountry]);

  useEffect(() => {}, [uploadedPhoto]);

  useEffect(() => {
    if (projectName.length > 0 && file) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [projectName, file]);

  const onDrop = useCallback((acceptedFiles) => {
    const doc = acceptedFiles[0];
    const validFileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    if (validFileTypes.includes(doc.type)) {
      if (doc.size <= 2097152) {
        var reader = new FileReader();
        reader.readAsText(doc, "UTF-8");
        reader.onload = function (evt) {
          let headers = evt.target.result
            .substr(0, evt.target.result.indexOf("\n"))
            .split(",");
          if (headers.includes("Phone") || headers.includes("Phone Number")) {
            setFile(doc);
            setShowError(false);
            setShowFile(true);
          } else {
            setError('Your file needs to contain "Phone" column');
            setShowFile(false);
            setShowError(true);
            setFile(null);
          }
        };
      } else {
        setError("Your file is too big. The limit for upload is 2MB");
        setShowFile(false);
        setShowError(true);
        setFile(null);
      }
    } else {
      setErrorFormat(
        "You are trying to upload wrong format. We accept only .csv, .xlsx file formats"
      );
      setShowFile(false);
      setShowErrorFormat(true);
      setFile(null);
    }
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const handlePhotoChange = (event) => {
    const img = event.target.files[0];
    const validImageTypes = ["image/png", "image/jpeg"];
    if (validImageTypes.includes(img.type)) {
      setShow(false);
      setUploadedPhoto(
        window.URL.createObjectURL(
          new Blob([img], {
            type: img.type,
          })
        )
      );
      setLogo(img);
      //setColor(hex);
    } else {
      alert("Please provide valid image");
    }
  };

  const addBrand = () => {
    setLoading(true);
    if (projectName.length === 0) {
      //TODO: set error modal
      setLoading(false);
      return;
    }
    if (choosedNumber.length === 0) {
      setLoading(false);
      return;
    }
    if (category.length === 0) {
      setLoading(false);
      return;
    }
    if (!file) {
      //TODO: set error modal
      setLoading(false);
      return;
    }
    if (user.projects) {
      for (let key in user.projects) {
        if (key === projectName) {
          //TODO: set error modal
          setLoading(false);
          return;
        }
      }
    }
    const fdata = new FormData();
    fdata.append("userId", user.userId);
    fdata.append("twilioSID", user.twilioSID);
    fdata.append("logo", logo);
    fdata.append("name", projectName);
    fdata.append("file", file);
    fdata.append("phone", choosedNumber);
    fdata.append("country", choosedCountry);
    fdata.append("category", category);
    let withLogo = false;
    if (logo !== null) {
      withLogo = true;
    }
    fdata.append("withLogo", withLogo);
    createBrand(fdata).then((response) => {
      if (response.status === 200) {
        response.json().then((rdata) => {
          let brands = user.projects || {};
          if (withLogo) {
            rdata.project["color"] = data.vibrant;
            addColor(user.userId, projectName, data.vibrant).then(
              (response) => {
                if (response.status === 200) {
                  //do nothing
                } else {
                  //do something?
                }
              }
            );
          }
          brands[projectName] = rdata.project;
          dispatch({ type: "addBrand", payload: brands });
          setLoading(false);
          window.location.href = "http://localhost:3000/settings/";
        });
      } else {
        //TODO: set error modal
        setLoading(false);
      }
    });
  };

  const options = ["one", "two", "three"];
  const defaultOption = options[0];

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        {show ? (
          <div className={styles.upload}>
            <label htmlFor="file-input-logo">
              {!uploadedPhoto && (
                <img
                  src="/assets/add_a_photo.svg"
                  className={styles.photo_icon}
                  alt="logo"
                />
              )}
              {uploadedPhoto && <img src={uploadedPhoto} alt="logo" />}
              <div className={styles.overlay} />
            </label>
            <input
              id="file-input-logo"
              name="logo"
              type="file"
              style={{ display: "none" }}
              onChange={handlePhotoChange}
            />
          </div>
        ) : (
          <div className={styles.uploadWithoutImg}>
            <label htmlFor="file-input-logo">
              {uploadedPhoto && <img src={uploadedPhoto} alt="logo" />}
              <div className={styles.overlay} />
            </label>
            <input
              id="file-input-logo"
              name="logo"
              type="file"
              style={{ display: "none" }}
              onChange={handlePhotoChange}
            />
          </div>
        )}
        <h2 className={styles.title}>Enter brand info</h2>
        <NameInput
          icon="/assets/file.svg"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
          placeholder="Enter brand name"
          style={{ marginTop: 16, textAlign: "center" }}
        />
        <Dropdown
          controlClassName={styles.country}
          options={countriesList}
          icon="/assets/earth.svg"
          placeholder="Choose your country"
          onChange={(e) => {
            setCountry(e.value);
          }}
        />

        {showFile ? (
          <div
            {...getRootProps({ style })}
            className={styles.dragNdropSuccess}
            style={{ marginTop: 16 }}
          >
            <input {...getInputProps()} />
            <img src={dropIconSuccess} alt="Your file" />
            <h4>{file.name}</h4>
          </div>
        ) : showError ? (
          <div
            {...getRootProps({ style })}
            className={styles.dragNdropError}
            style={{ marginTop: 16 }}
          >
            <input {...getInputProps()} />
            <img src="/assets/warning.svg" alt="Error" />
            <h4>{error}</h4>
          </div>
        ) : showErrorFormat ? (
          <div
            {...getRootProps({ style })}
            className={styles.dragNdropErrorFormat}
            style={{ marginTop: 16 }}
          >
            <input {...getInputProps()} />
            <img src="/assets/wrongformat.svg" alt="Drop file here" />
            <p>
              You are trying to upload wrong format. We accept only .csv, .xlsx file formats
            </p>
          </div>
        ) : (
          <div
            {...getRootProps({ style })}
            className={styles.dragNdrop}
            style={{ marginTop: 16 }}
          >
            <input {...getInputProps()} />
            <img src="/assets/upload.svg" alt="Drop file here" />
            <p>Drag and drop .csv, .xlsx file here</p>
          </div>
        )}
        <div className={styles.controls}>
          <button
            className={styles.cancel}
            onClick={() => history.goBack()}
          >
            Cancel
          </button>
          <button
            className={styles.save}
            disabled={
              !projectName || !choosedCountry || !file ? disabled : null
            }
            onClick={() => addBrand()}
          >
            Save
          </button>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Loader
              className={styles.loader}
              type="Oval"
              color="#ff7f32"
              height={100}
              width={100}
            />
          </div>
        )}
      </div>
    </div>
  );
};
