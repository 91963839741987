import { createStore } from 'redux';
import allReducers from './reducers';
import Cookies from 'universal-cookie';
import env from "react-dotenv";
import Cryptr from 'cryptr';

const cryptr = new Cryptr(env?.CRYPTR_SECRET || "PrestoTextTheBest21");
const cookies = new Cookies();

function isLeapYear(year) {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
}

function getDaysInMonth(year, month) {
  return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
}

function addMonths(date, value) {
  var d = new Date(date),
  n = date.getDate();
  d.setDate(1);
  d.setMonth(d.getMonth() + value);
  d.setDate(Math.min(n, getDaysInMonth(d.getFullYear(), d.getMonth())));
  return d;
}

function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("userData", serialisedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("userData");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

function saveToCookies(state) {
  try {
    const serialisedState = JSON.stringify(state);
    const encrypted = cryptr.encrypt(serialisedState);
    cookies.set("userData", encrypted, { path: '/', expires: addMonths(new Date(), 6) });
  } catch (e) {
    console.warn(e);
  }
}

function loadFromCookies() {
  try {
    const serialisedState = getCookie("userData") || cookies.get("userData");
    if (serialisedState === null) return undefined;
    const decrypted = cryptr.decrypt(serialisedState);
    return JSON.parse(decrypted);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

function getCookie(name) {
  name = name + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookies = decodedCookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
}

const store = createStore(allReducers, loadFromCookies());

store.subscribe(() => saveToCookies(store.getState()));

export default store