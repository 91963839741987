import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { getPaymentMethods, addPaymentMethod } from "../../../../requests.js";
import { PaymentMethods } from "../PaymentMethods";
import { AddNewPaymentMethods } from "../AddNewPaymentMethods/AddNewPaymentMethods";
import { useStructuredPaymentMethods } from "../../../../utils/hooks";
import Loader from "react-loader-spinner";

export const CreditCards = ({
  set,
  setInfo,
  activeCard,
  setActiveCard,
  activeCardStyle,
  setActiveCardStyle,
}) => {
  const user = useSelector((state) => state.user).user;

  const [paymentCards, setPaymentCards] = useState([]);
  const [loadingCard, setLoagingCard] = useState(false)

  const [newCard, setNewCard] = useState(false);
  const [cardHolder, setCardHolder] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [cardCvv, setCardCvv] = useState("");

  let month = +cardDate.slice(0, 2);
  let year = +cardDate.slice(3);

  const paymentMethods = useStructuredPaymentMethods(user.stripeId);

  const initCreditCardsInfo = useCallback(() => {
    getPaymentMethods(user.stripeId).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let paymentMethods = [];
          data.forEach((pm, index) => {
            let month = pm.card.exp_month.toString();
            if (pm.card.exp_month < 10) {
              month = "0" + pm.card.exp_month.toString();
            }
            let year = pm.card.exp_year.toString().slice(-2);
            paymentMethods.push({
              id: index,
              type: pm.card.brand,
              name: pm.billing_details.name,
              month: month,
              year: year,
              pm: pm.id,
              last4: pm.card.last4,
            });
          });
          setPaymentCards(paymentMethods);
          setActiveCard(paymentMethods[0]);
          set(paymentMethods[0]?.pm);
          setInfo([paymentMethods[0]?.last4, paymentMethods[0]?.type]);
        });
      }
    });
  }, [paymentMethods.data, set, setActiveCard, setInfo])

  useEffect(() => {
    initCreditCardsInfo();
  }, [initCreditCardsInfo]);

  const setter = (card) => {
    set(card.pm);
    setInfo([card.last4, card.type]);
    setActiveCard(card);
    setActiveCardStyle(!activeCardStyle);
  };

  const handleSubmit = (event) => {
    setLoagingCard(true);
    event.preventDefault();

    addPaymentMethod(
      user.stripeId,
      "card",
      cardHolder,
      cardNumber,
      month,
      year,
      cardCvv
    ).then((response) => {
      if (response.status === 200) {
        setLoagingCard(false);
        response.json().then((data) => setPaymentCards(data));
        initCreditCardsInfo();
      }
    });
    setNewCard(!newCard);
    setCardHolder("");
    setCardNumber("");
    setCardDate("");
    setCardCvv("");
  };

  return (
    <>
      <div className={styles.header}>
        <h4>Payment methods</h4>
        <span>{`${paymentCards.length} cards out of 4`}</span>
      </div>
      <div className={styles.cardWrapper}>
        {paymentCards.map(card => (
          <PaymentMethods
            name={card.name}
            type={card.type}
            expMonth={card.month}
            expYear={card.year}
            key={card.id}
            last4={card.last4}
            activeCard={activeCard === card}
            activeCardStyle={activeCardStyle}
            onClick={() => setter(card)}
          />
        ))}
        <div
          className={
            paymentCards.length === 4 ? styles.addNewCardWrapper : ""
          }
        >
          <AddNewPaymentMethods
            newCard={newCard}
            setNewCard={setNewCard}
            cardHolder={cardHolder}
            setCardHolder={setCardHolder}
            cardNumber={cardNumber}
            setCardNumber={setCardNumber}
            cardDate={cardDate}
            setCardDate={setCardDate}
            cardCvv={cardCvv}
            setCardCvv={setCardCvv}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
      {loadingCard && (
        <div className={styles.loaderContainer}>
          <Loader
            className={styles.loader}
            type="Oval"
            color="#ff7f32"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};
