import React from 'react'
import { PlusButton } from './common/PlusButton'
import styles from './styles.module.scss'

export const Balance = ({ balance, onPlus }) => {
  return (
    <div className={`${styles.main} ${!onPlus ? styles.orange : ''}`}>
      <div>
        <h6>Your balance</h6>
        <h3>
          {'$'+Number.parseFloat(balance.replace('$','')).toFixed(2)}
        </h3>
      </div>
      {onPlus && <PlusButton />}
    </div>
  )
}
