import React from 'react';
import styles from './styles.module.scss';
import check from '../../../../../public/assets/icons/check.svg';

export const SuccessModal = ({ type }) => {
  
  return (
    <div className={styles.main}>
      <img alt="success" src={check} />
      <h1 className={styles.title}>{`Your ${type} was sucessfuly changed`}</h1>
    </div>
  )
};