import React from "react";
import { useHistory, useLocation } from "react-router";
import { Card } from "../Card";
import styles from "./styles.module.scss";

export const CardSection = ({ page, set, cards, choosed, setChoosed }) => {
  const location = useLocation();
  const history = useHistory();

  return (
    <div className={styles.main}>
      {cards.map((cardContent, index) => (
        <Card
          {...cardContent}
          key={index}
          active={index === choosed}
          onClick={
            page !== "messages"
              ? () => {
                history.push(`/edit-brand/${cardContent.id}`);
              }
              : () => {
                  set(cardContent);
                  setChoosed(index);
                }
          }
        />
      ))}
      <Card onPlus={() => history.push("/add-brand")} />
    </div>
  );
};
