import React from "react";
import styles from "./styles.module.scss";

export const NameInput = ({
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  placeholder,
  name,
  refRegister,
  type = "text",
  style,
  defaultValue,
  icon
}) => {
  return (
    <div style={style} className={styles.main}>
      <img
        src={icon}
        width="20"
        height="20"
        alt="icon"
      />
      <input
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        ref={refRegister}
        type={type}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </div>
  );
};
