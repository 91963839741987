import env from "react-dotenv";

const SERVER_URL = env?.SERVER_URL || "http://localhost:8282";

export const loginWithToken = (encryptedToken) => {
  const body = {
    encryptedToken: encryptedToken
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/loginWithToken`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const createBrand = (data) => {
  const headers = {
    'Accept': '*/*'
  };
  return fetch(`${SERVER_URL}/createBrand`, {
    method: "POST",
    headers,
    body: data
  });
};

export const editBrand = (data) => {
  const headers = {
    'Accept': '*/*'
  };
  return fetch(`${SERVER_URL}/editBrand`, {
    method: "POST",
    headers,
    body: data
  });
};

export const addColor = (userId, project, color) => {
  const body = {
    color: color,
    project: project,
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/addColor`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const changeName = (userId, fullName) => {
  const body = {
    fullName: fullName,
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/changeName`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const changeImage = (data) => {
  const headers = {
    "Accept": "*/*"
  };
  return fetch(`${SERVER_URL}/changeAvatar`, {
    method: "POST",
    headers,
    body: data,
  });
};

export const changeFunctions = (userId, func, value) => {
  const body = {
    func: func,
    value: value,
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/changeFunctions`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getPaymentMethods = (stripeId) => {
  const body = {
    stripeId: stripeId
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/getPaymentMethods`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getFile = (userId, project) => {
  const body = {
    userId: userId,
    project: project
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/getFile`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const deleteBrand = (userId, project) => {
  const body = {
    userId: userId,
    project: project
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/deleteBrand`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const charge = (userId, stripeId, oldBalance, amount, paymentMethodId) => {
  const body = {
    userId: userId,
    stripeId: stripeId,
    amount: amount,
    paymentMethodId: paymentMethodId,
    oldBalance: oldBalance
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/charge`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const payout = (userId, amount, newBalance) => {
  const body = {
    userId: userId,
    amount: amount,
    newBalance: newBalance
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/payout`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getPayments = (stripeId) => {
  const body = {
    stripeId: stripeId
  };
  const headers = {
    "Content-Type": "application/json"
  };
  return fetch(`${SERVER_URL}/getPayments`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getCountries = () => {
  return fetch(`${SERVER_URL}/getCountries`, {
    method: "GET"
  });
};

export const getNumbers = (countryCode, type) => {
  const body = {
    countryCode: countryCode,
    type: type
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/getNumbers`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const sendMessage = (info, userId, project, history, price, subaccountSID) => {
  const body = {
    info: info,
    userId: userId,
    project: project,
    history: history,
    price: price,
    subaccountSID: subaccountSID
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/sendMessage`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getPrice = (countryCode) => {
  const body = {
    countryCode: countryCode
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/getPrice`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getMessages = () => {
  return fetch(`${SERVER_URL}/messages`, {
    method: "GET"
  });
}

export const getRecentMessages = (userId) => {
  const body = {
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/getRecentMessages`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const getCompanies = (userId) => {
  const body = {
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/getCompanies`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const verifyEmail = (email) => {
  const body = {
    email: email,
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/verifyNewEmail`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const changeEmail = (userId, email) => {
  const body = {
    email: email,
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/changeEmail`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const verifyPassword = (email, encryptedPassword) => {
  const body = {
    email: email,
    encryptedPassword: encryptedPassword
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/verifyNewPassword`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const changePassword = (userId, encryptedPassword) => {
  const body = {
    encryptedPassword: encryptedPassword,
    userId: userId
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/changePassword`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const addPaymentMethod = (stripeId, type, name, cardNumber, cardMonth, cardYear, cvc) => {
  const body = {
    type: type,
    stripeId: stripeId,
    cardNumber: cardNumber,
    cardMonth: cardMonth,
    cardYear: cardYear,
    cvc: cvc,
    name: name
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/addPaymentMethod`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};

export const removePaymentMethod = (stripeId, last4) => {
  const body = {
    stripeId: stripeId,
    last4: last4
  };
  const headers = {
    "Content-Type": "application/json",
  };
  return fetch(`${SERVER_URL}/removePaymentMethod`, {
    method: "POST",
    headers,
    body: JSON.stringify(body)
  });
};