import React, {useState} from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./App.scss";
import { Layout } from "./components/layout/Layout";
import { Main } from "./pages/Main";
import { Messages } from "./pages/Messages";
import { Payment } from "./pages/Payment";
import { Settings } from "./pages/Settings";
import { ROUTES } from "./utils/routes";
import "./index.scss";
import "./animations.scss";
import { AddModal } from "./components/common/modals/settings/AddModal";
import { ManageModal } from "./components/common/modals/settings/ManageModal";

const App = () => {
  const location = useLocation();

  return (
    <Layout>
      <SwitchTransition>
        <CSSTransition key={location.pathname} timeout={700} classNames="mount">
          <Switch key={location.pathname} location={location}>
            <Route path={ROUTES.HOME} exact component={Main} />
            <Route path={ROUTES.MESSAGES} exact component={Messages} />
            <Route path={ROUTES.PAYMENT} exact component={Payment} />
            <Route path={ROUTES.SETTINGS} component={Settings} />
            <Route path={ROUTES.LOGOUT} exact component={Main} />
            <Route path={ROUTES.ADD_BRAND} exact component={AddModal} />
            <Route path={`/edit-brand/:id`} exact component={ManageModal} />
          </Switch>
        </CSSTransition>
    </SwitchTransition>
    </Layout>
  );
};

export default App;
