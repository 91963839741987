import React from 'react'
import styles from './styles.module.scss'

export const BlackButton = ({ children, active, style, onClick }) => {
  return (
    <button onClick={onClick} className={styles.main} data-active={active} style={style}>
      {children}
    </button>
  )
}
