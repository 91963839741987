import React, { useState } from 'react';
import styles from './styles.module.scss';
import { OrangeButton } from '../../../buttons/OrangeButton';
import Loader from 'react-loader-spinner';
import { useCookies } from 'react-cookie';
import env from 'react-dotenv';

export const LogOutModal = () => {

  const [loading, setLoading] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['userData', 'token']);

  const logout = () => {
    setLoading(true);
    removeCookie("userData", { path: '/' });
    setLoading(false);
    window.location.href = env?.LANDING_URL || 'http://localhost:3005/';
  };

  return (
    <div className={styles.main}>
      <h1 className={styles.title}>Log Out</h1>
      <p className={styles.subTitle}>Are you sure you want to log out?</p>
      <OrangeButton
        onClick={() => logout()}
        style={{
          justifyContent: 'center',
          marginTop: 24,
          fontSize: 14,
          height: 42,
          borderRadius: 8,
          background: 'linear-gradient(90.54deg, #ffa56f 0%, #ff7f32 100%)'
        }}>
        Log out
      </OrangeButton>
      {loading && (
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} type="Oval" color="#ff7f32" height={100} width={100} />
        </div>
      )}
    </div>
  )
};