import React from "react";
import styles from "./styles.module.scss";
import plus from "../../../../../src/public/assets/payments/plus.svg";

export const AddNewPaymentMethods = ({
  newCard,
  setNewCard,
  cardHolder,
  setCardHolder,
  cardNumber,
  setCardNumber,
  cardDate,
  setCardDate,
  cardCvv,
  setCardCvv,
  handleSubmit,
}) => {
  const handleChangeCardHolder = (event) => {
    const { value } = event.target;
    let str = value.replace(/[^\D]/g, "");
    setCardHolder(str);
  };

  const handleChangeCardNumber = (event) => {
    const { value } = event.target;
    let number = value
      .replace(/\s/g, "")
      .match(/.{1,4}/g)
      ?.join(" ")
      .substr(0, 19);
    setCardNumber(number);
  };

  const handleChangeCardDate = (event) => {
    const { value } = event.target;
    let date = value
      .split("/")
      .join("")
      .match(/.{1,2}/g)
      ?.join("/");
    setCardDate(date);
  };

  const handleChangeCardCvv = (event) => {
    const { value } = event.target;
    let cvv = value.replace(/[^\d]/g, "");
    setCardCvv(cvv);
  };

  return (
    <>
      {newCard === false && (
        <div
          className={styles.addNewPaymentMethods}
          onClick={() => setNewCard(!newCard)}
        >
          <img src={plus} alt="plus" />
          <p>Add credit card</p>
        </div>
      )}
      {newCard && (
        <form
          className={styles.newPaymentMethods}
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            name="name"
            className={styles.name}
            value={cardHolder}
            placeholder="Cardholder name"
            onChange={handleChangeCardHolder}
            maxLength="32"
            required
          />
          <input
            className={styles.number}
            name="cardNumber"
            value={cardNumber}
            placeholder="**** **** **** ****"
            onChange={handleChangeCardNumber}
            required
          />
          <div className={styles.bottom}>
            <div>
              <input
                type="text"
                name="expiry"
                value={cardDate}
                className={styles.date}
                placeholder="XX / XX"
                maxLength="5"
                onChange={handleChangeCardDate}
                required
              />
              <input
                type="password"
                name="CVV"
                placeholder="***"
                className={styles.CVV}
                value={cardCvv}
                onChange={handleChangeCardCvv}
                maxLength="3"
                required
              />
            </div>
            <button className={styles.addNewCard} type="submit">
              Add
            </button>
          </div>
        </form>
      )}
    </>
  );
};
