import React from 'react'
import styles from './styles.module.scss'
import visa from '../../../../public/assets/payments/visa_blue.svg'
import mastercard from '../../../../public/assets/payments/mastercard.svg'
import apple from '../../../../public/assets/payments/apple.svg'
import paypal from '../../../../public/assets/payments/paypal_blue.svg'
import dollar from '../../../../public/assets/payments/dollar.svg'
import arrowUp from '../../../../public/assets/payments/arrowUp.svg'
import arrowDown from '../../../../public/assets/payments/arrowDown.svg'
import bubble from '../../../../public/assets/payments/bubble.svg'

export const RecentPayments = ({ amount, last4, type }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        {type === 'visa' ?
          <img style={{ marginTop: '10px' }} src={visa} alt="bank" />
          : type === 'mastercard' ?
            <img src={mastercard} alt="bank" />
            : type === 'apple' ?
              <img style={{ marginTop: '6px' }} src={apple} alt="bank" />
              : type === 'paypal' ?
                <img src={paypal} alt="bank" />
                : type === 'message' ?
                  <img style={{ marginTop: '8px' }} src={bubble} alt="bank" />
                  : null
        }
      </div>
      <div className={styles.textWrapper}>
        {type !== 'message' ?
          (<>
            <p className={styles.name}>{`**** **** **** ${last4}`}</p>
            <p className={styles.moto}>
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </p>
          </>)
          : (<>
            <p className={styles.name}>Messaging</p>
            <p className={styles.moto}>SMS</p>
            </>)
        }
      </div>
      {type !== 'message'
      ? <img
          style={{ height: '16px', marginLeft: '66px', marginTop: '24px' }}
          src={dollar}
          alt="dollar"
        />
      : <img
          style={{ height: '16px', marginLeft: '122px', marginTop: '24px' }}
          src={dollar}
          alt="dollar"
        />
      }
      
      <p className={styles.price}>{amount}</p>
      {type !== 'message'
      ? <img
          style={{ height: '12px', marginTop: '26px', marginLeft: '378px', position: 'absolute' }} src={arrowUp}
          alt="arrow"
        />
      : <img
          style={{ height: '12px', marginTop: '26px', marginLeft: '378px', position: 'absolute' }}
          src={arrowDown}
          alt="arrow"
        />
      }
    </div>
  )
}
