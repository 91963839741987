import React from 'react'
import styles from './styles.module.scss'

export const Select = ({ children: options }) => {
  return (
    <select className={styles.main}>
      {options}
    </select>
  )
}
