import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import { Balance } from "../../components/common/Balance";
import { CreditCards } from "./common/CreditCards";
import { RecentPayments } from "./common/RecentPayments";
import { charge, getPayments } from "../../requests";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";

export const Payment = () => {
  const user = useSelector((state) => state.user).user;
   const dispatch = useDispatch();
  const [topUp, setTopUp] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [balance, setBalance] = useState(user?.balance);
  const [payments, setPayments] = useState(user?.payments || []);
  const [cardInfo, setCardInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const topUpRef = useRef();

  const [activeCard, setActiveCard] = useState(null);
  const [activeCardStyle, setActiveCardStyle] = useState(false);

  useEffect(() => {
    if (payments.length === 0 && user?.stripeId) {
      getRecentPayments();
    }
  }, []);

  const getRecentPayments = () => {
    getPayments(user?.stripeId).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          let rp = [];
          data.intents.forEach((intent) => {
            rp.push({
              amount: intent.amount / 100,
              type: intent.type,
              last4: intent.last4,
            });
          });
          setPayments(rp);
          dispatch({ type: "payments", payload: rp });
        });
      }
    });
  };

  const chargeCustomer = () => {
    setLoading(true);
    let top = parseFloat(topUp.slice(2));
    charge(user.userId, user.stripeId, balance, top, paymentMethod).then(
      (response) => {
        if (response.status === 200) {
          setTopUp("");
          let bal = parseFloat(balance);
          bal += parseFloat(top);
          setBalance(bal);
          let rp = payments;
          rp.splice(0, 0, {
            amount: top,
            type: cardInfo[1],
            last4: cardInfo[0],
          });
          if (rp.length > 6) {
            rp.splice(6);
          }
          setPayments(rp);
          dispatch({ type: "balance", payload: bal });
          dispatch({ type: "payments", payload: rp });
          setLoading(false);
        } else {
          alert("Something went wrong");
          setLoading(false);
        }
      }
    );
  };

  const validateTopUp = (value) => {
    let tmp = "$ ";
    let n = value;
    if (n === tmp) {
      setTopUp("");
      return;
    }
    if (value.length >= 3) {
      n = value.substring(2);
    }
    if (!isNaN(n)) {
      setTopUp(tmp + n);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainBlock}>
          <div className={styles.topUp}>
            <div className={styles.graySection}>
              <p>Top up your wallet</p>
              <input
                value={topUp}
                onChange={
                  (e) => validateTopUp(e.target.value)
                }
                placeholder="$ 0"
                refRegister={topUpRef}
              />
            </div>
          </div>
          <div className={styles.paymentMethods}>
            <CreditCards
              set={setPaymentMethod}
              setInfo={setCardInfo}
              activeCard={activeCard}
              setActiveCard={setActiveCard}
              activeCardStyle={activeCardStyle}
              setActiveCardStyle={setActiveCardStyle}
            />
            <button
              className={
                `${styles.topUpAccount} ${topUp.length > 0 && activeCardStyle
                  ? styles.active
                  : ""
                }`
              }
              type="button"
              onClick={() => chargeCustomer()}
              disabled={!topUp.length || activeCardStyle === false}
            >
              Next
            </button>
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.recentMessage}>
            <Balance balance={`$ ${balance}`} />
          </div>
          <div>
            <div className={styles.topRecentPayment}>
              <h4>Recent payments</h4>
              <select>
                <option>All</option>
              </select>
            </div>
            {payments.map((payment, index) => (
              <RecentPayments
                key={index}
                amount={payment.amount}
                type={payment.type}
                last4={payment.last4}
              />
            ))}
          </div>
        </div>
      </div>
      {loading && (
        <div className={styles.loaderContainer}>
          <Loader
            className={styles.loader}
            type="Oval"
            color="#ff7f32"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};
