import React from 'react'
import styles from './styles.module.scss'

export const OrangeButton = ({
  onClick,
  children,
  icon,
  style,
  type = "button",
  disabled
}) => {
  if (disabled) {
    return (
      <button
        className={styles.button}
        onClick={onClick}
        style={style}
        type={type}
        disabled
      >
        {icon &&
          <img src={icon} alt="button icon" />}
        {children}
      </button>
    )
  }
  return (
    <button
      className={styles.button}
      onClick={onClick}
      style={style}
      type={type}
    >
      {icon &&
        <img src={icon} alt="button icon" />}
      {children}
    </button>
  )
}
