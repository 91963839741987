import covcheg from '../public/assets/logos/companies/covcheg.svg';
import young from '../public/assets/logos/companies/young.svg';
import rozetka from '../public/assets/logos/companies/rozetka.svg';
import quanti from '../public/assets/logos/companies/quanti.svg';

import add_card from '../public/assets/payments/add_card.svg';
import apple_pay from '../public/assets/payments/apple_pay.svg';
import credit_card from '../public/assets/payments/credit_card.svg';
import gpay from '../public/assets/payments/gpay.svg';
import paypal from '../public/assets/payments/paypal.svg';
import visa_black from '../public/assets/payments/visa_black.svg';
import visa from '../public/assets/payments/visa.svg';


export const RECENT_COMPANIES = [
  {
    logo: covcheg,
    name: 'KOVCHEG',
    category: 'Restaurant',
    time: '2 days',
    subs: '130k+',
    active: false,
  },
  {
    logo: young,
    name: 'Young Minds',
    category: 'Business Trainings',
    time: '17 days',
    subs: '89k+',
    active: false,
  },
  {
    logo: rozetka,
    name: 'ROZETKA',
    category: 'Online reseller',
    time: '85 days',
    subs: '1mil.+',
    active: false,
  },
  {
    logo: quanti,
    name: 'Quanti Coders',
    category: 'IT company',
    time: '15 days',
    subs: '50k+',
    active: false,
  },
]

export const RECENT_MESSAGES = [
  {
    name: 'KOVCHEG',
    time: "2 days ago",
    logo: young,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu mi bibendum placerat massa. ",
    count: "130,568",
    price: '50.68'
  },
  {
    name: 'KOVCHEG',
    time: "2 days ago",
    logo: young,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu mi bibendum placerat massa. ",
    count: "130,568",
    price: '50.68'
  },
];

export const COMPANIES_CARDS = [{
  id: 1,
  name: 'Quanti Coders',
  color: 'red',
  icon: quanti,
}, {
  id: 2,
  name: 'ROZETKA',
  color: 'green',
  icon: rozetka,
}]

export const VARIANTS = {
  Name: '{Name}',
  Surname: '{Surname}',
  'Text 1': `Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore laudantium sunt esse. Quaerat, incidunt? Necessitatibus officiis et minima libero quos ipsum repellendus molestiae asperiores, non rerum veritatis ex voluptas molestias.`
}

export const RECENT_FUNCTIONS = [
  {
    id: 1,
    name: 'Email Notifications',
    category: 'Allow us to send you articles and useful information',
    active: false,
    tag: 'emailNotifications'
  },
 /*  {
    id: 2,
    name: 'Function 1',
    category: 'Allow blah-blah-blah',
    active: false,
    tag: 'func1'
  },
  {
    id: 3,
    name: 'Function 2',
    category: 'Allow blah-blah-blah',
    active: false,
    tag: 'func2'
  }, */
];

export const CREDIT_CARDS = [
  {
    id: 0,
    content: apple_pay,
  },
  {
    id: 1,
    content: gpay,
  },
  {
    id: 2,
    content: paypal,
  },
  {
    id: 3,
    content: credit_card,
  },
  {
    id: 4,
    content: visa,
  },
  {
    id: 5,
    content: visa_black,
  },
  {
    id: 6,
    content: add_card,
  },
]