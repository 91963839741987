import React from "react";
import { AnimatedSelectedIcon } from "../../../../components/common/AnimatedSelectedIcon";
import styles from "./styles.module.scss";

export const Card = ({
  name,
  logo,
  color = "#F5F5F7",
  onPlus,
  onClick,
  active,
}) => {
  return (
    <div
      className={styles.card}
      data-active={active}
      style={{ backgroundColor: color }}
      onClick={onClick || onPlus}
    >
      {!onPlus ? (
        !active ? (
          <img src={logo} alt="logo" height={72} width={72} />
        ) : (
          <AnimatedSelectedIcon color={color} />
        )
      ) : (
        <img src="/assets/plus.svg" alt="" />
      )}
      <h2 className={!name && styles.title}>{name || "New project"}</h2>
    </div>
  );
};
