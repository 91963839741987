import React from "react";
import styles from "./styles.module.scss";

export const Message = ({ project, date, logo, text, count, price }) => {
  let oldDate = new Date(date);
  let newDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  let diffDays = Math.round(Math.abs((oldDate - newDate) / oneDay)) - 1;
  if (diffDays < 0) {
    diffDays += 1;
  }
  return (
    <div className={styles.main}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="logo" />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.name}>
            <h5>{project}</h5>
            <span>
              {diffDays !== 0
                ? diffDays === 1
                  ? `${diffDays} day ago`
                  : `${diffDays} days ago`
                : "today"}
            </span>
          </div>
          <div className={styles.info}>
            <div className={styles.header_info}>
              <img src="/assets/dollar.svg" alt="" />
              <span>{price}</span>
            </div>
            <div className={styles.header_info}>
              <img src="/assets/fire.svg" alt="" />
              <span>{count} messages</span>
            </div>
          </div>
        </div>
        <p>{text}</p>
      </div>
    </div>
  );
};
