import React from 'react'
import { BlackButton } from '../buttons/BlackButton'
import styles from './styles.module.scss'

export const Recent = ({ logo, name, category, time, subs, onClick, active, isFucntionMode = false }) => {

  const getButtonLabel = () => {
    if (!isFucntionMode) return "View stats";
    if (active) return "On";
    return "Off";
  }

  let oldDate = new Date(time);
  let newDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000;
  let diffDays = Math.round(Math.abs((oldDate - newDate) / oneDay))-1;
  if (diffDays < 0) {
    diffDays += 1
  }
  return (
    <div className={`${styles.main} ${active ? styles.active : ''}`}>
      <div className={styles.info}>
        {logo && <img src={logo} alt={name} />}
        <div className={`${styles.name} ${(!time && !subs) ? styles.fullWidth : ''}`}>
          <h4>{name}</h4>
          <h5>{category}</h5>
        </div>
        {time && <div className={styles.time}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0C3.58862 0 0 3.58862 0 8C0 12.4114 3.58862 16 8 16C12.4114 16 16 12.4114 16 8C16 3.58862 12.4114 0 8 0ZM11.8047 12.1379C11.6747 12.2679 11.504 12.3334 11.3334 12.3334C11.1627 12.3334 10.9919 12.2679 10.8621 12.1379L7.52869 8.80469C7.40332 8.68005 7.33337 8.51062 7.33337 8.33337V4C7.33337 3.63135 7.63196 3.33337 8 3.33337C8.36804 3.33337 8.66663 3.63135 8.66663 4V8.05737L11.8047 11.1953C12.0653 11.4561 12.0653 11.8773 11.8047 12.1379Z" fill={active ? "white" : "black"} />
          </svg>
          <span>
              {diffDays!==0 ? diffDays===1 ? `${diffDays} day` : `${diffDays} days` : 'today'}
          </span>
        </div>}
        {subs && <div className={styles.subs}>
          <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.3629 7.7925C10.2874 8.83459 9.6619 9.56619 8.93971 9.87625C8.17396 10.2049 7.39996 10.0236 7.01352 9.425C6.38084 8.44481 6.81559 7.76263 7.23599 7.10288C7.71268 6.35478 8.25302 5.50684 7.73477 3.85791C7.32565 2.55613 6.5244 1.46322 5.35324 0.609531C4.98815 0.343437 4.6419 0.143312 4.3609 0C4.75337 0.907562 5.26374 2.59641 4.73062 4.46228C4.39249 5.64581 3.49887 6.54269 2.63465 7.41003C1.4964 8.55247 0.319336 9.73378 0.319336 11.6213C0.319336 13.0855 0.856336 14.2519 1.91537 15.0882C2.71734 15.7214 3.53568 15.968 3.54384 15.97H5.49655L5.45568 15.9692C5.44815 15.9637 4.70421 15.4058 4.6914 14.766C4.68543 14.467 4.85062 14.1802 5.18237 13.9135C5.68068 13.5129 5.95455 13.0816 6.01971 12.5949C6.05955 12.2973 6.01927 11.9826 5.89684 11.643C6.72334 11.9723 7.57827 12.6913 7.86799 13.5838C8.12818 14.3853 7.88749 15.2097 7.17262 15.97H9.30968C10.7095 14.9281 11.4976 13.6796 11.6521 12.2591C11.849 10.4479 10.9774 8.742 10.3629 7.7925Z" fill={active ? "white" : "black"} />
          </svg>
          <span>
            {subs}
          </span>
        </div>}
      </div>
      <BlackButton
        style={{
          maxWidth: !isFucntionMode ? 120 : 50,
          height: !isFucntionMode ? 40 : 32
        }}
        active={active}
        onClick={onClick}
      >
        {getButtonLabel()}
      </BlackButton>
    </div>
  )
}
