import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SettingInput } from "../../../../components/common/inputs/SettingInput";
import styles from "./styles.module.scss";

export const EditAccountData = () => {
  const user = useSelector((state) => state.user).user;

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [userNewPassword, setUserNewPassword] = useState('');

  const handleChange = (setter) => (event) => {
    const { value } = event.target;
    setter(value);
  }

  return (
    <div className={styles.main}>
      <form action="">
        <div className={styles.section}>
          <h4>Edit your data</h4>
          <div>
            <div className={styles.inputContainer}>
              <img src="/assets/badge.svg" alt="user" />
              <input
                type="text"
                value={userName}
                onChange={handleChange(setUserName)}
                placeholder="Change your name"
              />
            </div>
            <div className={styles.inputContainer}>
              <img src="/assets/email.svg" alt="user" />
              <input
                type="email"
                value={userEmail}
                onChange={handleChange(setUserEmail)}
                placeholder="Change your email"
              />
            </div>
            <div className={styles.inputContainer}>
              <img src="/assets/password.svg" alt="user" />
              <input
                type="password"
                value={userPassword}
                onChange={handleChange(setUserPassword)}
                placeholder="Enter password"
              />
            </div>
            <div className={styles.inputContainer}>
              <img src="/assets/password.svg" alt="user" />
              <input
                type="password"
                value={userNewPassword}
                onChange={handleChange(setUserNewPassword)}
                placeholder="Enter new password"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
