import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import logo from "../../../public/assets/logos/logo.svg";
import logout from "../../../public/assets/logout.svg";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import { NavIcon } from "./common/NavIcon";
import { loginWithToken } from "../../../requests";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { ModalView } from "../../common/ModalView";
import env from "react-dotenv";

import chat from "../../../public/assets/icons/chat.svg";
import home from "../../../public/assets/icons/home.svg";
import homeActive from "../../../public/assets/icons/homeActive.svg";
import manage from "../../../public/assets/icons/manage.svg";
import manageActive from "../../../public/assets/icons/manageActive.svg";
import pay from "../../../public/assets/icons/pay.svg";
import payActive from "../../../public/assets/icons/payActive.svg";

export const Navbar = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["token", "userData"]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const login = (token) => {
    loginWithToken(token).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch({ type: "login", payload: data.userData });
        });
      } else {
        window.location.href = env.LANDING_URL + "?login=true";
      }
    });
  };

  let query = useQuery();
  useEffect(() => {
    if (!cookies.userData) {
      const token = query.get("token");
      if (token) {
        login(token);
      } else {
        window.location.href = env.LANDING_URL + "?login=true";
      }
    }
  }, []);

  const location = useLocation();
  const mathcLocation = (route) =>
    route === "/"
      ? matchPath(location.pathname, route)?.isExact
      : matchPath(location.pathname, route);
  return (
    <div className={styles.Navbar}>
      <div className={styles.header}>
        <NavLink to="/">
          <img src={logo} alt="logo" />
        </NavLink>
      </div>
      <div className={styles.mainNav}>
        <NavLink to={ROUTES.HOME}>
          <NavIcon
            src={home}
            activeSrc={homeActive}
            active={mathcLocation(ROUTES.HOME)}
          />
        </NavLink>
        <NavLink to={ROUTES.MESSAGES}>
          <NavIcon
            src={chat}
            activeSrc={"/assets/messages.svg"}
            active={mathcLocation(ROUTES.MESSAGES)}
          />
        </NavLink>
        <NavLink to={ROUTES.PAYMENT}>
          <NavIcon
            src={pay}
            activeSrc={payActive}
            active={mathcLocation(ROUTES.PAYMENT)}
          />
        </NavLink>
        <NavLink to={ROUTES.SETTINGS}>
          <NavIcon
            src={manage}
            activeSrc={manageActive}
            active={mathcLocation(ROUTES.SETTINGS)}
          />
        </NavLink>
      </div>
      <NavLink
        to={location.pathname + "?logout=true"}
        className={styles.logout}
      >
        <img
          src={logout} alt="logout"
        />
      </NavLink>
      <ModalView homePath={location.pathname} />
    </div>
  );
};
