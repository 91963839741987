import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import johndoe from "../../public/assets/profiles/johndoe.svg";
import { CardSection } from "../Messages/common/CardSection";
import { NameInput } from "../../components/common/inputs/NameInput";
import { RECENT_FUNCTIONS } from "../../utils/mocks";
import { Promo } from "./common/Promo";
import { EditAccountData } from "./common/EditAccountData";
import { Plan } from "./common/Plan";
import { Recent } from "../../components/common/Recent";
import { PaymentCards } from "./common/PaymentCards";
import { Notification } from "./common/Notification";
import {
  changeImage,
  changeFunctions,
  changeName,
  changeEmail,
  changePassword,
} from "../../requests";
import { useLocation } from "react-router";
import Loader from "react-loader-spinner";
import { Route, Switch } from "react-router-dom";
import { SettingsNav } from "./common/Nav";
import { useStructuredPaymentMethods } from "../../utils/hooks";
import edit from "../../../src/public/assets/icons/edit.svg"

export const Settings = () => {
  const user = useSelector((state) => state.user).user;
  const [image, setImage] = useState(user?.avatar || johndoe);
  const [brands] = useState(user?.projects || {});
  const [name, setName] = useState(user?.fullName);
  const nameRef = useRef();
  const dispatch = useDispatch();
  const [functions, setFunctions] = useState([]);
  const [loading, setLoading] = useState(false);

  const paymentMethods = useStructuredPaymentMethods(user.stripeId);

  const search = useLocation().search;
  const resetEmail = new URLSearchParams(search).get("resetEmail");
  const email = new URLSearchParams(search).get("email");
  const resetPassword = new URLSearchParams(search).get("resetPassword");
  const password = new URLSearchParams(search).get("password");

  useEffect(() => {
    if (resetEmail === "true" && email) {
      setLoading(true);
      changeEmail(user?.userId, email).then((response) => {
        if (response.status === 200) {
          dispatch({ type: "changeEmail", payload: email });
          window.location.href =
            "http://localhost:3000/settings/?success=true&type=email";
        } else {
          alert("Something went wrong");
          setLoading(false);
        }
      });
    }
    if (resetPassword === "true" && password) {
      setLoading(true);
      changePassword(user?.userId, password).then((response) => {
        if (response.status === 200) {
          window.location.href =
            "http://localhost:3000/settings/?success=true&type=password";
        } else {
          alert("Something went wrong");
          setLoading(false);
        }
      });
    }
  }, []);

  useEffect(() => {
    createFunctions();
  }, [image, user]);

  const changeAvatar = (files) => {
    setImage(
      window.URL.createObjectURL(
        new Blob([files[0]], {
          type: "image/png",
        })
      )
    );
    let data = new FormData();
    data.append("image", files[0]);
    data.append("userId", user.userId);
    changeImage(data).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          dispatch({ type: "changeAvatar", payload: data.url });
        });
      } else {
        response.json().then((error) => {
          console.log(error.error);
        });
      }
    });
  };

  const changeFunction = (func, value) => {
    changeFunctions(user.userId, func, value).then((response) => {
      if (response.status === 200) {
        dispatch({ type: "changeFunctions", payload: [func, value] });
      } else {
      }
    });
  };

  const createFunctions = () => {
    let funcs = RECENT_FUNCTIONS;
    funcs.forEach((func) => {
      if (user[func.tag] !== null) {
        func.active = user[func.tag];
      }
    });
    setFunctions(funcs);
  };

  const cards = useMemo(() => {
    return Object.entries(brands).map((brand) => ({
      ...brand[1],
      id: brand[0],
    }));
  }, [brands]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (name.length > 0 && name !== user.fullName) {
        nameRef.current.blur();
        changeName(user.userId, name).then((response) => {
          if (response.status === 200) {
            dispatch({ type: "changeName", payload: name });
          }
        });
      } else {
        setName(user.fullName);
        nameRef.current.blur();
      }
    }
  };

  const blur = () => {
    if (name.length > 0 && name !== user.fullName) {
      changeName(user.userId, name).then((response) => {
        if (response.status === 200) {
          dispatch({ type: "changeName", payload: name });
        }
      });
    } else {
      setName(user.fullName);
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainBlock}>
          <div className={styles.info}>
            <div className={styles.upload}>
              <label htmlFor="file-input">
                <img src={image} alt="profile pic" />
                <div className={styles.overlay} />
                <input
                  onChange={(e) => changeAvatar(e.target.files)}
                  id="file-input"
                  type="file"
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <div className={styles.name}>
              <h2>{name}</h2>
              {/* <NameInput
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => blur()}
                onKeyPress={handleKeyPress}
                refRegister={nameRef}
                style={{ textAlign: "left" }}
              /> */}
              <h5>Starter!</h5>
            </div>
          </div>
          <Switch>
            <Route path="/settings/recent">
              <div className={styles.recent}>
                <h4>Recent</h4>
                <div className={styles.mainRecent}>
                  {functions.map((functionItem) => (
                    <Recent
                      {...functionItem}
                      key={functionItem.id}
                      active={functionItem.active}
                      onClick={() =>
                        setFunctions(
                          functions.map((item) => {
                            if (item.id === functionItem.id) {
                              item.active = !item.active;
                              changeFunction(item.tag, item.active);
                            }
                            return item;
                          })
                        )
                      }
                      isFucntionMode
                    />
                  ))}
                </div>
              </div>
            </Route>
            <Route path="/settings/payments">
              <div className={styles.titlePayment}>
                <h4>Payment Methods</h4>
              </div>
              <div className={styles.cardsWrapper}>
                {paymentMethods.data.map((card, index) => (
                  <PaymentCards
                    length={paymentMethods.data.length}
                    onDeleteCard={paymentMethods.deleteCard}
                    index={index}
                    key={card.id}
                    {...card}
                  />
                ))}
              </div>
            </Route>
            <Route path="/settings/brands">
              <div>
                <h4>Edit your brands</h4>
                <CardSection cards={cards} />
              </div>
            </Route>
            <Route path="/settings" exact>
              <EditAccountData />
              <button className={styles.delete}>
                <img src="/assets/trash.svg" alt="icon" />
                Remove my account
              </button>
            </Route>
            <Route path="/settings/promo" >
              <Promo onReedem={() => {}} />

              <div className={styles.notifications}>
                <h2 className={styles.title}>Notifications</h2>
                <div className={styles.notifications}>
                  <Notification
                    title="Email Notifications"
                    subtitle="Allow us to send you articles and useful information"
                  />
                </div>
                <div className={styles.notifications}>
                  <Notification
                    title="Function 2"
                    subtitle="Allow blah-blah-blah"
                  />
                </div>
                <div className={styles.notifications}>
                  <Notification
                    title="Function 3"
                    subtitle="Allow blah-blah-blah"
                  />
                </div>
              </div>
            </Route>
          </Switch>
        </div>
        <div className={styles.rightBlock}>
          {/* <Plan plan={user?.plan || "Starter"} />
           */}
          <SettingsNav />
        </div>
      </div>
      {(loading || paymentMethods.deleteLoading) && (
        <div className={styles.loaderContainer}>
          <Loader
            className={styles.loader}
            type="Oval"
            color="#ff7f32"
            height={100}
            width={100}
          />
        </div>
      )}
    </>
  );
};
