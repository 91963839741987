import React from "react";
import styles from "./styles.module.scss";

export const BrandCard = ({ name, logo, active, onClick }) => {
  return (
    <button onClick={onClick} className={styles.root + ' ' + (active && styles['root-active'])}>
      <div className={styles.info}>
        <img src={logo} alt="logo" />
        <span>{name}</span>
      </div>
      <p className={styles.status}>{active ? 'Viewing' : 'View stats'}</p>
    </button>
  );
};
