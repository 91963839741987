import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getPrice } from "../../../../requests";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import styles from "./styles.module.scss";

const MAX_VALUE = 160;

export const MessageForm = ({
  messageParams,
  records,
  choosedProject,
  setOutcome,
  setLoading,
  disabled,
}) => {
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user).user;
  const [text, setText] = useState('');
  console.log(history)

  // disabled ? "" : "Greetings, {Name}. We are finally..."

  const regexp = () => {
    if (messageParams.length > 0) {
      const regexpArr = messageParams.map((p) => `{${p}}`);
      return new RegExp(regexpArr.join("|"), "g");
    } else {
      return null;
    }
  };

  const getTextLength = useCallback(
    (text) =>
      text.replaceAll(new RegExp(regexp, "ig"), (match) =>
        match.replaceAll(/{|}/gi, "")
      ).length,
    [regexp]
  );

  const currentSymbolsLength = useMemo(
    () => getTextLength(text),
    [getTextLength, text]
  );
  const initialText = new URLSearchParams(location.search).get("text");

  useEffect(() => {}, [messageParams]);

  useEffect(() => {
    if (initialText) {
      setText(initialText);
    }
  }, [initialText]);

  const send = () => {
    setLoading(true);
    let phones = [];
    let messages = [];
    records.forEach((record) => {
      if (record["Phone Number"]) {
        phones.push(record["Phone Number"]);
      } else {
        phones.push(record["Phone"]);
      }
      let message = text;
      messageParams.forEach((msg) => {
        if (message.includes("{" + msg + "}")) {
          message = message.replace("{" + msg + "}", record[msg]);
        }
      });
      messages.push(message);
    });
    let out = [];
    for (var i = 0; i < phones.length; i++) {
      out.push({ phone: phones[i], msg: messages[i] });
    }
    console.log(phones, messages, out);
    getPrice(choosedProject.country).then((response) => {
      if (response.status === 200) {
        response.json().then((data) => {
          setOutcome({
            info: out,
            twilioSID: user.twilioSID,
            balance: user.balance,
            userId: user.userId,
            history: user.projects[choosedProject.name].history || [],
            initialText: text,
            recentPayments: user.payments || [],
            text: messages[messages.length - 1],
            project: choosedProject.name,
            logo: choosedProject.icon,
            recentMessages: user.recentMessages || [],
            count: phones.length,
            price: data.price,
          });
          setLoading(false);
          history.push(`${location.pathname}?cost=true`);
        });
      }
    });
  };

  return (
    <div className={disabled && styles.disabled}>
      <h2 className={styles.title}>Enter text</h2>
      <div className={styles.textArea}>
        {messageParams.length !== 0 && !disabled ? (
          <ul className={styles.variants}>
            {messageParams?.slice(0, 5).map((key, index) => (
              <li
                className={styles.variant}
                key={key}
              >
                <button
                  onClick={() => {
                    if (currentSymbolsLength + key.length <= MAX_VALUE) {
                      setText(`${text} {${key}}`);
                    }
                  }}
                  className={styles.tab}
                >
                  {key}
                </button>
              </li>
            ))}
          </ul>
        ) : null}
        {disabled ? (
          <div className={styles.noMessageLabel}>
            <p>Please, choose a brand from the list</p>
          </div>
        ) : (
          <div className={styles.formControls}>
            <HighlightWithinTextarea
              className={styles.textInput}
              value={text}
              onChange={(e) => {
                if (getTextLength(e) <= MAX_VALUE) {
                  setText(e);
                } else setText(text);
              }}
              highlight={regexp()}
            />
            <button
              onClick={send}
              className={styles.sendButton}
              disabled={!text.length ? true : false}
            >
              <img src="/assets/Message.svg" alt="send" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
