import React from "react";
import styles from "./styles.module.scss";
import visa from "../../../../../src/public/assets/icons/visa.svg";
import mastercard from "../../../../../src/public/assets/icons/mastercard.svg";
import basket from "../../../../../src/public/assets/icons/basket.svg";

export const PaymentCards = (props) => {
  const {
    type,
    month,
    year,
    last4,
    index,
    onDeleteCard,
    name,
    length
  } = props;

  if (type === "visa") {
    return(
      <div
        className={styles.wrapper}
        style={{ background: "linear-gradient(79.64deg, #1A2FEA 0%, #B753EC 100%)" }}
      >
        <div className={styles.top}>
          <div className={styles.name}>{name}</div>
          <div className={styles.number}>
            {`**** **** **** ${last4}`}
          </div>
          <button
            onClick={() => onDeleteCard(index)}
            className={length === 1 ? styles.disable : ""}
          >
            <img
              src={basket}
              width="9"
              height="12"
              alt="icon"
            />
            <span>Remove</span>
          </button>
        </div>
        <div className={styles.bottom}>
          <div className={styles.expiry}>
            {`${month} / ${year}`}
          </div>
          <div className={styles.type}>
            <img src={visa} alt="visa" />
          </div>
        </div>
      </div>
    );
  } else if (type === "mastercard") {
    return(
      <div
        className={styles.wrapper}
        style={{ background: "linear-gradient(259.64deg, #BE3E80 0%, #EC7453 100%);" }}
      >
        <div className={styles.top}>
          <div className={styles.number}>
            {`**** **** **** ${last4}`}
          </div>
          <button onClick={() => onDeleteCard(index)}>
            Remove
          </button>
        </div>
        <div className={styles.bottom}>
          <div className={styles.expiry}>
            {`${month} / ${year}`}
          </div>
          <div className={styles.type}>
            <img src={mastercard} alt="mastercard" />
          </div>
        </div>
      </div>
    );
  }


}

