import React from 'react'
import { Navbar } from '../Navbar'
import styles from './styles.module.scss'

export const Layout = ({ children }) => {
  return (
    <div className={styles.Layout}>
      <Navbar />
      <div className={styles.PageWrapper}>
        {children}
      </div>
    </div>
  )
}
