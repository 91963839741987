import React, { useEffect, useState } from 'react';
import { OrangeButton } from '../../../buttons/OrangeButton'
import styles from './styles.module.scss'
import { sendMessage, payout, getMessages } from '../../../../../requests';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import { useHistory, useLocation } from 'react-router';
import Table from '../../../Table/table.jsx';

export const CostModal = ({ data }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [numberOfErrors, setNumberOfErrors] = useState(0);
  const [messages, setMessages] = useState([]);

  const validatePhone = (phone) => {
    if (phone.startsWith('+')) {
      let isnum = /^\d+$/.test(phone.slice(1));
      if (isnum) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (data) {
      let errors = [];
      data.info.forEach((record, index, object) => {
        if (!validatePhone(record.phone)) {
          errors.push(record.phone);
          object.splice(index, 1);
        }
      });
      if (errors.length !== 0) {
        setNumberOfErrors(errors.length);
        setShowWarning(true);
      }
    }
  }, []);

  useEffect(async () => {
      if (showTable) {
        if (messages.length !== 0) {
          while (messages[messages.length - 1].status === 'queued') {
            await new Promise(r => setTimeout(r, 3000));
            getMessages(data.userID).then(response => {
              if (response.status === 200) {
                response.json().then(data => {
                  console.log(data.messages);
                  setMessages(data.messages);
                });
              }
            });
          }
        }
      }
    }, [showTable, messages]); 

  const send = () => {
    setLoading(true);
    if (parseFloat(data.price) * data.info.length > parseFloat(data.balance)) {
      alert('Not enough funds');
      setLoading(false);
      return;
    } else {
      payout(data.userId, parseFloat(data.price) * data.info.length, parseFloat(data.balance) - parseFloat(data.price) * data.info.length).then(res => {
        if (res.status === 200) {
          dispatch({ type: 'balance', payload: parseFloat(data.balance) - parseFloat(data.price) * data.info.length });
          sendMessage(data.info, data.userId, data.project, data.history, parseFloat(data.price) * data.info.length, data.twilioSID).then(response => {
            if (response.status === 200) {
              setLoading(false);
              let today = new Date();
              let dd = String(today.getDate()).padStart(2, '0');
              let mm = String(today.getMonth() + 1).padStart(2, '0');
              let yyyy = today.getFullYear();
              today = mm + '/' + dd + '/' + yyyy;
              let recent = data.recentMessages;
              recent.splice(0, 0, { text: data.text, logo: data.logo, project: data.project, count: data.info.length, price: parseFloat(data.price) * data.info.length, date: today });
              let rp = data.recentPayments;
              rp.splice(0, 0, { amount: parseFloat(data.price) * data.info.length, type: 'message' });
              if (rp.length > 6) {
                rp.splice(6);
              }
              dispatch({ type: 'payments', payload: rp });
              dispatch({ type: 'messages', payload: recent });
              response.json().then(msg => {
                msg.messages.forEach(message => {
                  message.text = message.text.substring(0, message.text.indexOf(' ', 25)) + '...';
                });
                setMessages(msg.messages);
                setShowTable(true);
              });
            }
          });
        }
      })
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Phone',
        accessor: 'to'
      },
      {
        Header: 'Message',
        accessor: 'text'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
    ],
    []
  );

  const edit = () => {
    history.push(`${location.pathname}?manage=${data.project}&callback=http://localhost:3000/messages/?text=${data.initialText}`)
  };

  if (!showWarning) {
    if (showTable) {
      return (
        <div className={styles.main}>
          <h1 className={styles.title}>Message Callback</h1>
          <p className={styles.subTitle}>See through the end as your messages are being sent. Or just close this modal and have a rest :)</p>
          <Table columns={columns} data={messages} />
        </div>
      );
    } else {
      return (
        <div className={styles.main}>
          <h1 className={styles.title}>Cost calculation</h1>
          <p className={styles.subTitle}>{`You are going to send ${data?.info?.length || ''} messages. It will cost you $${data?.info?.length * data?.price || ''}. Do you agree?`}</p>
          <OrangeButton
            onClick={() => send()}
            style={{
              justifyContent: 'center',
              marginTop: 24,
              fontSize: 14,
              height: 42,
              borderRadius: 8,
              background: 'linear-gradient(90.54deg, #ffa56f 0%, #ff7f32 100%)'
            }}>
            Confirm
          </OrangeButton>
          {loading && (
            <div className={styles.loaderContainer}>
              <Loader className={styles.loader} type="Oval" color="#ff7f32" height={100} width={100} />
            </div>
          )}
        </div>
      );
    }
  } else {
    return (
      <div className={styles.main}>
        <h1 className={styles.title}>Invalid data</h1>
        <p className={styles.subTitle}>{`You have ${numberOfErrors === 1 ? numberOfErrors + `invalid phone number` : numberOfErrors + `invalid phone numbers`} in your file. Maybe you want to change your file. Or just continue with valid ones.`}</p>
        <div className={styles.buttons}>
          <OrangeButton
            onClick={() => edit()}
            style={{
              justifyContent: 'center',
              marginTop: 24,
              fontSize: 14,
              height: 42,
              borderRadius: 8,
              background: 'linear-gradient(90.54deg, #ffa56f 0%, #ff7f32 100%)'
            }}>
            Change file
          </OrangeButton>
          <OrangeButton
            onClick={() => { setShowWarning(false) }}
            style={{
              justifyContent: 'center',
              marginTop: 24,
              fontSize: 14,
              marginLeft: 20,
              height: 42,
              borderRadius: 8,
              background: 'linear-gradient(90.54deg, #ffa56f 0%, #ff7f32 100%)'
            }}>
            Continue
          </OrangeButton>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Loader className={styles.loader} type="Oval" color="#ff7f32" height={100} width={100} />
          </div>
        )}
        {/*  <ModalView homePath="/" data={data}/> */}
      </div>
    );
  }
};