import React from "react";
import styles from "./styles.module.scss";
import { OrangeButton } from "../../../buttons/OrangeButton";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";

export const SubscribeModal = () => {
  return (
    <div className={styles.main}>
      <div>
        <h1 className={styles.title}>Subscription settings</h1>
        <p className={styles.subTitle}>
          Here you can change your subscription or pause it, till you need it
          again
        </p>
        <div className={styles.card_container}>
          <div className={styles.card + " " + styles.advanced}>
            <img src="/assets/business.svg" alt="" />
            <p>Advanced</p>
            <p>$24.95/month</p>
          </div>
          <div className={styles.card + " " + styles.pause}>
            <img src="/assets/business.svg" alt="" />
            <p>Pause subscription</p>
            <p>$99.95/month</p>
          </div>
        </div>
      </div>
      <OrangeButton
        style={{
          justifyContent: "center",
          marginTop: 24,
          fontSize: 14,
          height: 42,
          borderRadius: 8,
          background: "linear-gradient(90.54deg, #ffa56f 0%, #ff7f32 100%)",
          maxWidth: 385
        }}
      >
        Next
      </OrangeButton>
    </div>
  );
};
