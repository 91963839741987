import React from 'react'
import styles from './styles.module.scss'
import visa from '../../../../public/assets/icons/visa.svg'

export const PaymentMethods = ({
  name,
  last4,
  type,
  expMonth,
  expYear,
  activeCard,
  activeCardStyle,
  onClick,
}) => {
  if (type === 'visa') {
    return (
      <>
        <div
          className={
            `${styles.method} ${activeCard && activeCardStyle
              ? styles.active
              : ''
            }`
          }
          onClick={onClick}
          style={{ background: 'linear-gradient(79.64deg, #1A2FEA 0%, #B753EC 100%)' }}
        >
          <p className={styles.name}>{name}</p>
          <p className={styles.number}>{`****  ****  ****  ${last4}`}</p>
          <div className={styles.bottom}>
            <p className={styles.date}>{`${expMonth} / ${expYear}`}</p>
            <img src={visa} alt="visa" />
          </div>
        </div>
      </>
    );
  }
};